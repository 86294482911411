export default [
  {
    path: "/",
    text: "naslovna",
  },
  {
    path: "/najave",
    text: "najave",
  },
  {
    path: "/onama",
    text: "o nama",
  },
  {
    path: "/izvestaji",
    text: "izveštaji",
  },
  {
    path: "/staze_kosmaja",
    text: "staze kosmaja",
  },
  {
    path: "/galerija",
    text: "galerija",
  },
]
